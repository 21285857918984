export enum METRICGOAL {
  SEARCH = 'SEARCH',
  CATEGORISE = 'CATEGORISE',
  EVALUATE = 'EVALUATE',
  URL_PARSE = 'URL_PARSE',
  FEATURE_REQUEST = 'FEATURE_REQUEST',
  BUG = 'BUG',
}

export class Metric {
  id: string;

  name: string;

  status: string;

  metricName: string;

  query: string;

  goal: METRICGOAL;

  values: string[];

  examples: string[];

  createdAt: string;

  updatedAt: string;

  companyId: string;

  productId: string;

  constructor(
    id: string,
    name: string,
    metricName: string,
    query: string,
    values: string[],
    examples: string[],
    createdAt: string,
    updatedAt: string,
    companyId: string,
    productId: string
  ) {
    this.id = id;
    this.name = name;
    this.status = 'ACTIVE';
    this.metricName = metricName;
    this.query = query;
    this.goal = METRICGOAL.SEARCH;
    this.values = values;
    this.examples = examples;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.companyId = companyId;
    this.productId = productId;
  }
}
