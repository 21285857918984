import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/auth/me',
    login: '/auth/signIn',
    register: '/auth/signUp',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  pathfix: {
    gfile: '/oauth/method/googledrive/call',
  },
  dashboard: {
    timeline: {
      insight: '/dash/timeline/insights',
      request: `/dash/timeline/request/insights`,
    },
  },
  users: {
    list: '/users',
    count: '/users/count',
    id: (id: string) => `/users/${id}`,
    companies: (id: string) => `/users/${id}/companies`,
  },
  companies: {
    list: '/companies',
    count: '/companies/count',
    id: (id: string) => `/companies/${id}`,
    products: (id: string) => `/companies/${id}/products`,
    logo: (id: string) => `/companies/${id}/logo`,
    favIcon: (id: string) => `/companies/${id}/favicon`,
    settings: (id: string) => `/company/${id}/settings`,
  },
  products: {
    list: '/products',
    count: '/products/count',
    id: (id: string) => `/products/${id}`,
  },
  feedback: {
    list: '/feedbacks',
    count: '/feedbacks/count',
    id: (id: string) => `/feedbacks/${id}`,
    insight: `/feedbacks/insight`,
    insightId: (id: string) => `/feedbacks/${id}/insight`,
    export: `/feedbacks/export`,
    metrics: `/feedbacks/insight/metrics`,
    issues: {
      list: '/feedbacks/insight/issues',
      count: '/feedbacks/insight/issues/count',
      update: (id: string, issueId: string) => `/feedbacks/insight/${id}/issues/${issueId}`,
      export: '/feedbacks/insight/issues/export',
    },
    validations: {
      list: '/feedbacks/insight/validations',
      count: '/feedbacks/insight/validations/count',
      update: (id: string, validationId: string) =>
        `/feedbacks/insight/${id}/validations/${validationId}`,
      export: '/feedbacks/insight/validations/export',
    },
    profiles: {
      list: '/feedbacks/insight/profile',
      count: '/feedbacks/insight/profile/count',
    },
  },
  request: {
    list: '/request',
    name: '/request/name',
    issues: {
      list: '/request/issues',
      count: '/request/issues/count',
      export: '/request/issues/export',
      breakdown: {
        list: '/request/issues/breakdown',
        count: '/request/issues/breakdown/count',
      },
      insights: {
        list: (id: string) => `/request/issues/${id}/insights`,
        count: (id: string) => `/request/issues/${id}/insights/count`,
      },
    },
    values: {
      list: '/request/values',
      count: '/request/values/count',
      export: '/request/values/export',
      breakdown: {
        list: '/request/values/breakdown',
        count: '/request/values/breakdown/count',
      },
      insights: {
        list: (id: string) => `/request/values/${id}/insights`,
        count: (id: string) => `/request/values/${id}/insights/count`,
      },
    },
  },
  integrations: {
    list: '/integrations',
    id: (id: string) => `/integrations/${id}`,
    count: '/integrations/count',
    types: '/integrations/types',
    lang: '/integrations/lang',
    image: `/integrations/image`,
    exports: (id: string) => `/integrations/${id}/export-feedbacks`,
    options: '/integration-options',
    connection: {
      status: '/integrations/connection/status/',
      call: '/integrations/connection/call/',
    },
    questions: {
      types: '/integrations/questions/types',
      wizard: '/integrations/questions/wizard',
      id: (id: string) => `/integrations/${id}/questions`,
      style: (id: string) => `/integrations/${id}/style`,
    },
    page: {
      id: (id: string) => `/integrations/${id}/pages`,
      route: (id: string) => `/integrations/pages/${id}/routes`,
    },
    theme: {
      list: '/integrations/theme',
      count: '/integrations/theme/count',
      id: (id: string) => `/integrations/theme/${id}`,
    },
    metrics: {
      integration: (iId: string) => `/integrations/${iId}/metrics`,
      action: (iId: string, mId: string) => `/integrations/${iId}/metrics/${mId}`,
    },
    answers: (id: string) => `/integrations/${id}/feedback/answers/total`,
  },
  metrics: {
    list: '/metrics',
    count: '/metrics/count',
    insights: 'metrics/insights',
    total: '/metrics/insights/total',
    requestTotal: '/metrics/request/insights/total',
    integration: (id: string) => `/metrics/${id}/integration`,
    content: {
      list: '/metrics/content/template',
      count: '/metrics/content/template/count',
      id: (id: string) => `/metrics/content/template/${id}`,
    },
  },
  crm: {
    contact: {
      list: '/crm/contacts',
      count: '/crm/contacts/count',
      id: (id: string) => `/crm/contacts/${id}`,
    },
    organization: {
      list: '/crm/organizations',
      count: '/crm/organizations/count',
      id: (id: string) => `/crm/organizations/${id}`,
    },
  },
  campaigns: {
    list: '/campaigns',
    count: '/campaigns/count',
    performance: '/campaigns/performance',
    id: (id: string) => `/campaigns/${id}`,
    phase: (id: string) => `/campaigns/${id}/phase`,
    clone: (id: string) => `/campaigns/${id}/clone`,
    launch: (id: string) => `/campaigns/${id}/launch`,
    performanceId: (id: string) => `/campaigns/${id}/performance`,
    contentWizard: '/campaign/content/wizard',
    sessions: {
      list: (id: string) => `/campaigns/${id}/session`,
      count: (id: string) => `/campaigns/${id}/session/count`,
      feedback: (id: string) => `/campaigns/${id}/sessions/feedback`,
      answers: (id: string) => `/campaigns/${id}/sessions/feedback/answers/total`,
      export: (id: string) => `/campaigns/${id}/sessions/feedback/answers/total/export`,
    },
    templates: {
      list: '/campaigns/content/template',
      count: '/campaigns/content/template/count',
      id: (id: string) => `/campaigns/content/template/${id}`,
    },
  },
};
