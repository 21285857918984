// src/hooks/useFeedbackDrawer.ts
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'src/components/snackbar';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import { useFeedbackContext } from 'src/components/feedback/context';
import { useAuthContext } from 'src/auth/hooks';
import { deleteFeedbackById, getInsightById, reSendFeedbackToProcess } from 'src/services/feedback';
import { useBoolean } from 'src/hooks/use-boolean';
import { Feedback } from 'src/models/feedback';

export const useFeedbackDrawer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { copy } = useCopyToClipboard();
  const { open, onClose, selectedFeedback, showQuestions, refreshPage } = useFeedbackContext();
  const { user } = useAuthContext();
  const confirm = useBoolean(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<Feedback | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedSummary, setExpandedSummary] = useState(true);

  const onCopy = useCallback(
    (text: string) => {
      if (!text) return;
      copy(text).then(() => {
        enqueueSnackbar(t('success.copied'), { variant: 'success' });
      });
    },
    [copy, enqueueSnackbar, t]
  );

  const handleClick = useCallback(() => {
    if (selectedFeedback?.id) onCopy(selectedFeedback.id);
  }, [onCopy, selectedFeedback]);

  const reSendFeedback = () => {
    if (selectedFeedback?.id) {
      enqueueSnackbar(t('text.labels.resendingFeedback'), { variant: 'info' });
      reSendFeedbackToProcess(selectedFeedback?.id).then((res) => {
        enqueueSnackbar(res ? t('success.resendingFeedback') : t('error.resendingFeedback'), {
          variant: res ? 'success' : 'error',
        });
      });
    }
  };

  const deleteFeedback = () => {
    if (selectedFeedback?.id) {
      setSendLoading(true);
      enqueueSnackbar(t('text.labels.deleteFeedback'), { variant: 'info' });
      deleteFeedbackById(selectedFeedback?.id).then((res) => {
        enqueueSnackbar(res ? t('success.deleteFeedback') : t('error.deleteFeedback'), {
          variant: res ? 'success' : 'error',
        });
        if (res) {
          confirm.onFalse();
          refreshPage(true);
          onClose();
        }
        setSendLoading(false);
      });
    }
  };

  useEffect(() => {
    if (selectedFeedback) {
      setLoading(true);
      getInsightById(selectedFeedback.id).then((res) => {
        if (res && res.length > 0) {
          setFeedback(res[0]);
        }
        setLoading(false);
      });
    } else {
      setFeedback(null);
    }
  }, [selectedFeedback]);

  return {
    t,
    user,
    open,
    onClose,
    selectedFeedback,
    showQuestions,
    confirm,
    sendLoading,
    loading,
    feedback,
    expanded,
    setExpanded,
    expandedSummary,
    setExpandedSummary,
    handleClick,
    reSendFeedback,
    deleteFeedback,
    setSendLoading,
  };
};
