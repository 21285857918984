import getHeader from './header';
import axios, { endpoints } from '../utils/axios';
import { clearFilterToSend } from '../components/dataTable/utils';
import { Metric } from '../models/metric';

export async function getMetricsList(companyId: string, filters?: any) {
  try {
    const header = await getHeader();
    const filter = {
      where: { company: companyId },
      order: 'metrics_name ASC',
    };

    if (filters) {
      filter.where = { ...filter.where, ...clearFilterToSend(filters, 'metrics') };
    }
    const response = await axios.get(
      `${endpoints.metrics.insights}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getMetrics(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'updatedAt DESC',
  filters?: any,
  fields?: any
): Promise<Metric[]> {
  try {
    const header = await getHeader();
    const filter: any = {
      skip,
      limit,
      where: { companyId, status: ['ACTIVE'], ...clearFilterToSend(filters, 'metrics') },
      order: order || 'name ASC',
    };

    if (fields) filter.fields = fields;

    const response = await axios.get(
      `${endpoints.metrics.list}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getMetricsCount(companyId: string, filters?: any) {
  try {
    const header = await getHeader();
    const filter = { companyId, status: ['ACTIVE'], ...clearFilterToSend(filters, 'users') };
    const response = await axios.get(
      `${endpoints.metrics.count}?where=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data.count;
    }
    return 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export async function postMetric(data: any) {
  try {
    const header = await getHeader();
    const response = await axios.post(`${endpoints.metrics.list}`, data, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function patchMetric(data: any, id: string) {
  try {
    const header = await getHeader();
    const response = await axios.patch(`${endpoints.metrics.list}/${id}`, data, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function deleteMetric(id: string) {
  try {
    const header = await getHeader();
    const response = await axios.patch(
      `${endpoints.metrics.list}/${id}`,
      { status: 'DEPRECATED' },
      header
    );

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getMetricWithTotals(companyId: string, metricName: string, filters?: any) {
  try {
    const header = await getHeader();
    const filter = {
      where: { company: companyId, 'LOWER(metrics.name)': metricName },
      order: 'metrics_values_total DESC',
      limit: 10,
      offset: 0,
    };

    if (filters) {
      filter.where = { ...filter.where, ...clearFilterToSend(filters, 'metrics') };
    }

    const response = await axios.get(
      `${endpoints.metrics.total}?filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getMetricsByRequest(
  companyId: string,
  requestIds: string[],
  metricName: string[],
  where?: any
) {
  try {
    const header = await getHeader();

    const clearWhere = clearFilterToSend({ ...where }, 'insights');

    if (clearWhere.metricsName) {
      // Merge metricName with clearWhere.metricName in clearWhere.metricName
      clearWhere.metricsName = { inq: [...clearWhere.metricsName.inq, ...metricName] };
    } else {
      clearWhere.metricsName = { inq: metricName };
    }

    const requestFilter = {
      where: { company: companyId, 'requestMessages.id': { inq: requestIds }, ...clearWhere },
      order: 'metricValuesTotal DESC',
      limit: 10,
      offset: 0,
    };

    const response = await axios.get(
      `${endpoints.metrics.requestTotal}?filter=${JSON.stringify(requestFilter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getMetricsByInsights() {
  try {
    const header = await getHeader();
    const response = await axios.get(`${endpoints.metrics.insights}/`, header);

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getIntegrationListByMetrics(metricId: string): Promise<any[]> {
  try {
    const header = await getHeader();

    const filter = {};

    const response = await axios.get(
      `${endpoints.metrics.integration(metricId)}?id=${metricId}&filter=${JSON.stringify(filter)}`,
      header
    );

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// get metric content list
export async function getMetricContentList(): Promise<Metric[]> {
  try {
    const header = await getHeader();
    const response = await axios.get(endpoints.metrics.content.list, header);

    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

// get metric content by id
export async function getMetricContentById(metricId: string): Promise<Metric | null> {
  try {
    const header = await getHeader();
    const response = await axios.get(`${endpoints.metrics.content}/${metricId}`, header);

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}
