import { FormControl, InputLabel } from '@mui/material';
import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InfoButton } from '../../infoButton/infoButton';
import { RenderLabel } from './parseLabelLang';

interface InputSelectListProps {
  id: string;
  label: string;
  value: any;
  values: string[] | { label: any; value: string }[];
  update: (id: string, value: any) => void;
  defaultValue: any;
  multiple?: boolean;
  sx?: any;
  helperText?: string;
}

export default function InputSelectList(props: InputSelectListProps) {
  const {
    value,
    values,
    update,
    id,
    label,
    defaultValue,
    helperText,
    multiple = false,
    sx,
  } = props;

  return (
    <FormControl fullWidth>
      <InputLabel
        shrink
        sx={{
          fontSize: '1.1rem !important',
          color: (theme) => `${theme.palette.text.primary} !important`,
          ml: -1,
          ...(helperText && { mt: -1, display: 'flex', alignItems: 'center' }),
        }}
      >
        {label}
        {helperText && helperText !== '' && (
          <InfoButton text={helperText} size="medium" buttonSize={45} iconSize={25} />
        )}
      </InputLabel>
      <Select
        multiple={multiple}
        labelId={label}
        id={label}
        value={value || defaultValue}
        onChange={(event) => update(id, event.target.value)}
        inputProps={{
          name: label,
          id: label,
        }}
        sx={(theme) => ({
          my: 2,
          ...sx,
        })}
        variant="outlined"
      >
        {values.map((v) =>
          typeof v === 'string' ? (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ) : (
            <MenuItem key={v.value} value={v.value}>
              {RenderLabel({ name: v.label })}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
