import React, { useEffect, useState } from 'react';
import Stack from '@mui/system/Stack';
import Button from '@mui/material/Button';
import { HistoryEdu } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../context';
import { Metric, METRICGOAL } from '../../../models/metric';
import InputText from '../../dynamicRequestForm/utils/InputText';
import { RenderLabel } from '../../dynamicRequestForm/utils/parseLabelLang';
import InputSelectList from '../../dynamicRequestForm/utils/InputSelectList';
import { Product } from '../../../models/product';
import { ListInput } from '../../integration/list/questionFields';
import CustomPopover, { usePopover } from '../../custom-popover';
import { getMetricContentList } from '../../../services/metrics';
import Scrollbar from '../../scrollbar/scrollbar';

interface MetricsFormsProps {
  products: Product[];
  sendLoading: boolean;
  sendItem: (item: Metric) => void;
}

export default function MetricsForm(props: MetricsFormsProps) {
  const { products, sendLoading, sendItem } = props;
  const { t } = useTranslation();
  const { selectedCompany, metric } = useDataContext();

  const [metrics, setMetrics] = useState<Metric[]>([]);
  const metricsPopover = usePopover();

  const [mData, setMData] = useState<Metric>(
    metric ||
      new Metric(
        '',
        '',
        '',
        '',
        [],
        [],
        '',
        '',
        selectedCompany,
        products && products.length > 0 ? products[0].id : ''
      )
  );

  useEffect(() => {
    if (selectedCompany) {
      getMetricContentList().then((res) => {
        setMetrics(res);
      });
    }
  }, [selectedCompany]);

  return (
    <Stack direction="column" spacing={2}>
      {metrics.length > 0 && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ pr: 1, pl: 2.5, py: 1 }}
        >
          <Button
            color="primary"
            endIcon={<HistoryEdu />}
            variant="outlined"
            onClick={metricsPopover.onOpen}
          >
            {t('text.buttons.useTemplates')}
          </Button>
          <CustomPopover
            open={metricsPopover.open}
            onClose={metricsPopover.onClose}
            arrow="top-center"
            sx={{ width: 350 }}
          >
            <Scrollbar
              sx={{
                maxHeight: 300,
                '& .SimpleBar-content': {
                  padding: 0,
                },
              }}
            >
              <Stack
                spacing={2}
                sx={{ p: 2 }}
                divider={<Divider sx={{ borderStyle: 'solid' }} variant="middle" />}
              >
                {metrics.map((item) => (
                  <ListItemButton
                    key={item.id}
                    onClick={() => {
                      item.id = '';
                      setMData((prevState) => ({ ...prevState, ...item }));
                      metricsPopover.onClose();
                    }}
                  >
                    {item.name}
                  </ListItemButton>
                ))}
              </Stack>
            </Scrollbar>
          </CustomPopover>
        </Stack>
      )}
      <InputText
        id="name"
        value={mData.name}
        defaultValue={mData.name}
        label={RenderLabel({
          name: {
            en: 'Name',
            es: 'Nombre',
            da: 'Navn',
            fi: 'Nimi',
            no: 'Navn',
            sv: 'Namn',
          },
        })}
        type="text"
        update={(id: string, value: any) => setMData({ ...mData, name: value })}
        disabled={false}
      />
      <InputText
        id="metricName"
        value={mData.metricName}
        defaultValue={mData.metricName}
        label={RenderLabel({
          name: {
            en: 'Metric Name',
            es: 'Nombre de la métrica',
            da: 'Metrisk navn',
            fi: 'Metrinen nimi',
            no: 'Metrikknavn',
            sv: 'Metriknamn',
          },
        })}
        type="text"
        update={(id: string, value: any) => setMData({ ...mData, metricName: value })}
        disabled={false}
      />
      <InputSelectList
        id="productId"
        label={RenderLabel({
          name: {
            en: 'Product',
            es: 'Producto',
            da: 'Produkt',
            fi: 'Tuote',
            no: 'Produkt',
            sv: 'Produkt',
          },
        })}
        value={mData.productId}
        values={products.map((p) => ({ value: p.id, label: p.name })) || []}
        update={(id: string, value: any) => setMData({ ...mData, productId: value })}
        defaultValue={mData.productId}
      />
      <InputSelectList
        id="goal"
        label={RenderLabel({
          name: {
            en: 'Goal',
            es: 'Objetivo',
            da: 'Mål',
            fi: 'Tavoite',
            no: 'Mål',
            sv: 'Mål',
          },
        })}
        value={mData.goal}
        values={[
          METRICGOAL.BUG,
          METRICGOAL.CATEGORISE,
          METRICGOAL.EVALUATE,
          METRICGOAL.FEATURE_REQUEST,
          METRICGOAL.SEARCH,
          METRICGOAL.URL_PARSE,
        ]}
        update={(id: string, value: any) => {
          if (value === METRICGOAL.FEATURE_REQUEST || value === METRICGOAL.BUG) {
            setMData({ ...mData, goal: value, query: '' });
          } else if (value === METRICGOAL.URL_PARSE) {
            setMData({ ...mData, goal: value, values: [], examples: [] });
          } else {
            setMData({ ...mData, goal: value });
          }
        }}
        defaultValue={mData.goal}
      />

      <InputText
        id="query"
        value={mData.query}
        defaultValue={mData.query}
        multiline
        label={RenderLabel({
          name: {
            en: 'Query',
            es: 'Consulta',
            da: 'Forespørgsel',
            fi: 'Kysely',
            no: 'Spørsmål',
            sv: 'Förfrågan',
          },
        })}
        type="text"
        disabled={[METRICGOAL.FEATURE_REQUEST, METRICGOAL.BUG].includes(mData.goal)}
        update={(id: string, value: any) => setMData({ ...mData, query: value })}
      />

      <ListInput
        key="value"
        label={RenderLabel({
          name: {
            en: 'Values',
            es: 'Valores',
            da: 'Værdier',
            fi: 'Arvot',
            no: 'Verdier',
            sv: 'Värden',
          },
        })}
        disabled={[METRICGOAL.URL_PARSE].includes(mData.goal)}
        values={mData.values || []}
        callback={(newValues) => {
          setMData({ ...mData, values: newValues });
        }}
      />

      <ListInput
        key="examples"
        label={RenderLabel({
          name: {
            en: 'Examples',
            es: 'Ejemplos',
            da: 'Eksempler',
            fi: 'Esimerkit',
            no: 'Eksempler',
            sv: 'Exempel',
          },
        })}
        disabled={[METRICGOAL.URL_PARSE].includes(mData.goal)}
        values={mData.examples || []}
        callback={(newValues) => {
          setMData({ ...mData, examples: newValues });
        }}
      />
      <LoadingButton
        variant="contained"
        color="inherit"
        loading={sendLoading}
        disabled={
          !mData.name ||
          !mData.metricName ||
          !mData.productId ||
          !mData.goal ||
          ([METRICGOAL.FEATURE_REQUEST, METRICGOAL.BUG].includes(mData.goal) && !mData.query) ||
          ([METRICGOAL.URL_PARSE].includes(mData.goal) && (!mData.values || !mData.examples))
        }
        onClick={() => sendItem(mData)}
        sx={{ m: 'auto', width: '90%' }}
      >
        {metric?.id ? t('text.buttons.update') : t('text.buttons.send')}
      </LoadingButton>
    </Stack>
  );
}
