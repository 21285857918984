export const allowFollowup = ['TEXT', 'LONGTEXT', 'NUMBER'];

export class IntegrationQuestion {
  id: string;

  title: string;

  type: string;

  ref: string;

  require: boolean;

  followup: boolean;

  value: string[];

  defaultValue: string;

  position: number;

  assets: any;

  integrationPageId: string;

  status: string;

  constructor(
    title: string = '',
    type: string = '',
    ref: string = '',
    require: boolean = false,
    followup: boolean = false,
    defaultValue: string = '',
    position: number = 0,
    pageId: string = ''
  ) {
    this.id = `draft-${Math.random().toString(36).substring(7)}`;
    this.title = title;
    this.type = type;
    this.ref = ref + Math.random().toString(36).substring(7);
    this.require = require;
    this.followup = followup;
    this.defaultValue = defaultValue;
    this.position = position;
    this.assets = getAssets(type);
    this.integrationPageId = pageId;
    this.status = 'DRAFT';
    this.value = getValues(type);
  }
}

export function getValues(type: string): string[] {
  switch (type) {
    case 'CONSENT':
      return ['Agree', 'Disagree'];
    case 'BOOLEAN':
      return ['Yes', 'No'];
    case 'MULTIPLECHOISE_IMAGE':
      return ['{position:1,value:"",url:""}'];
    default:
      return [];
  }
}

export function getAssets(type: string): any {
  switch (type) {
    case 'TEXT':
      return { placeholder: '', maxCharacters: '' };

    case 'LONGTEXT':
      return { placeholder: '', maxCharacters: '', extraOption: false };

    case 'NUMBER':
      return { placeholder: '' };

    case 'RATING_NUMBER':
      return { min: '0', max: '10', minPlaceholder: '', maxPlaceholder: '' };

    case 'RADIO':
      return { randomPosition: false, extraOption: false };

    case 'MULTIPLECHOICE':
      return { exclusiveAnswers: [], extraOption: false, maxOptions: '0', minOptions: '0' };

    case 'MULTIPLECHOISE_IMAGE':
      return { randomPosition: false, multiOption: false, addTitle: false };

    case 'CONSENT':
      return {};

    case 'SELECT':
      return { placeholder: '' };

    case 'DATE':
      return { placeholder: '' };

    case 'BOOLEAN':
      return { addIcon: false };

    case 'PASSWORD':
      return { placeholder: '' };

    case 'RATING_EMOJI':
      return { min: '0', max: '10', minPlaceholder: '', maxPlaceholder: '' };

    case 'EMAIL':
      return { placeholder: '' };

    case 'CONTACT':
      return { contactName: '', contactEmail: '' };

    case 'RATING_STAR':
      return {};

    case 'MULTI_QUESTION_MATRIX':
      return { exclusiveAnswers: [], randomPosition: false };

    case 'POINT_SYSTEM':
      return { randomPosition: false };

    case 'PRIORITY_LIST':
      return { randomPosition: false };

    case 'INFO_PAGE':
      return { placeholder: '' };

    default:
      return {};
  }
}
