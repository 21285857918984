import React, { useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../scrollbar/scrollbar';
import FilterName from '../dataTable/elements/filterName';
import { renderTypeOrIcon } from '../dynamicRequestForm/utils';
// models
import { ReturnType } from '../custom-popover/use-popover';
import { IntegrationQuestionType } from '../../models/integration-question-type';
import { useDataContext } from '../data';

interface Props {
  questionsTypePopover: ReturnType;
  onSelect: (ty: IntegrationQuestionType) => void;
  selected?: string;
}

export default function QuestionTypeDialog(props: Props) {
  const { questionsTypePopover, onSelect, selected } = props;
  const { integrationTypes } = useDataContext();
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');

  return (
    <Dialog
      open={!!questionsTypePopover.open}
      onClose={questionsTypePopover.onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <Stack direction="row" alignItems="flex-end" spacing={2} p={2} width={1}>
          <FilterName
            filterString={filter}
            onFilters={(name, value) => {
              setFilter(value);
            }}
          />
        </Stack>
        <Scrollbar sx={{ maxHeight: 310 }}>
          <List sx={{ width: '100%', maxHeight: 310 }}>
            {['BASIC', 'WIDGET'].map((type) => (
              <Stack key={type}>
                <Typography variant="subtitle2" gutterBottom ml={2} mt={2}>
                  {t(`text.questionType.${type}`)}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2} mb={2}>
                  {integrationTypes
                    .filter(
                      (ty) =>
                        ty.type === type &&
                        t(`text.questionType.${ty.id}`).toLowerCase().includes(filter.toLowerCase())
                    )
                    .map((ty) => (
                      <Grid item xs={6} md={3} key={ty.id}>
                        <ListItemButton
                          onClick={() => {
                            onSelect(ty);
                          }}
                          sx={{
                            borderRadius: 1,
                            ...(selected === ty.id && {
                              backgroundColor: (theme) => theme.palette.action.selected,
                            }),
                          }}
                        >
                          <ListItemIcon
                            sx={{ ...(selected === ty.id && { color: 'primary.main' }) }}
                          >
                            {renderTypeOrIcon(ty.id, { height: '20px' })}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`text.questionType.${ty.id}`)}
                            sx={{ ...(selected === ty.id && { color: 'primary.main' }) }}
                          />
                        </ListItemButton>
                      </Grid>
                    ))}
                </Grid>
              </Stack>
            ))}
          </List>
        </Scrollbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={questionsTypePopover.onClose} color="primary">
          {t('text.buttons.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
