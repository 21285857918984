import { m } from 'framer-motion';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { varPath } from 'src/components/animate'; // ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function SvgPath() {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      component={m.svg}
      viewBox="0 0 24 24"
      sx={{
        width: 20,
        height: 20,
        m: 1,
        stroke: PRIMARY_MAIN,
      }}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <defs>
        <linearGradient x1="100%" y1="9.946%" x2="50%" y2="50%" id="a">
          <stop stopColor={PRIMARY_DARK} offset="0%" />
          <stop stopColor={PRIMARY_MAIN} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="b">
          <stop stopColor={PRIMARY_LIGHT} offset="0%" />
          <stop stopColor={PRIMARY_MAIN} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="c">
          <stop stopColor={PRIMARY_LIGHT} offset="0%" />
          <stop stopColor={PRIMARY_MAIN} offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="nonzero">
        <m.path
          {...varPath}
          d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z"
          fill="url(#a)"
        />
      </g>
    </Box>
  );
}
