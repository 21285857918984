import { OptionConfig, OptionType } from '../../../models/optionConfig';
import User from '../../../models/user';
import Company from '../../../models/company';
import { Product } from '../../../models/product';
import { Metric, METRICGOAL } from '../../../models/metric';

export const forms: {
  user: (user: User | null, selectedCompany: string, companies: Company[]) => OptionConfig[];
  company: (company: Company | null, verticals: string[]) => OptionConfig[];
  product: (
    product: Product | null,
    selectedCompany: string,
    companies: Company[],
    langs: string[]
  ) => OptionConfig[];
  metric: (metric: Metric | null, selectedCompany: string, products: Product[]) => OptionConfig[];
} = {
  user: (user, selectedCompany, companies) => [
    // name
    {
      ref: 'name',
      name: {
        en: 'Name',
        es: 'Nombre',
        da: 'Navn',
        fi: 'Nimi',
        no: 'Navn',
        sv: 'Namn',
      },
      type: OptionType.TEXT,
      require: true,
      defaultValues: [user?.name || ''],
      possibleValues: [],
    },
    // lastname
    {
      ref: 'lastname',
      name: {
        en: 'Lastname',
        es: 'Apellido',
        da: 'Efternavn',
        fi: 'Sukunimi',
        no: 'Etternavn',
        sv: 'Efternamn',
      },
      type: OptionType.TEXT,
      require: true,
      defaultValues: [user?.lastname || ''],
      possibleValues: [],
    },
    // role
    {
      ref: 'role',
      name: {
        en: 'Role',
        es: 'Rol',
        da: 'Rolle',
        fi: 'Rooli',
        no: 'Rolle',
        sv: 'Roll',
      },
      type: OptionType.SELECT,
      require: true,
      defaultValues: [user?.dataUser?.role || 'USER'],
      possibleValues: ['USER', 'EDITOR', 'ROBOT'],
    },
    // companyId
    {
      ref: 'companyId',
      name: {
        en: 'Company',
        es: 'Empresa',
        da: 'Virksomhed',
        fi: 'Yritys',
        no: 'Selskap',
        sv: 'Företag',
      },
      type: OptionType.SELECT,
      require: true,
      defaultValues: [user?.companyId || selectedCompany],
      possibleValues: companies?.map((c) => c.id),
    },
  ],
  product: (product, selectedCompany, companies, langs) => [
    // name
    {
      ref: 'name',
      name: {
        en: 'Name',
        es: 'Nombre',
        da: 'Navn',
        fi: 'Nimi',
        no: 'Navn',
        sv: 'Namn',
      },
      type: OptionType.TEXT,
      require: true,
      defaultValues: [product?.name || ''],
      possibleValues: [],
    },
    // ipAllowed
    {
      ref: 'ipAllowed',
      name: {
        en: 'IP Allowed',
        es: 'IP Permitida',
        da: 'IP Tilladt',
        fi: 'IP Sallittu',
        no: 'IP Tillatt',
        sv: 'IP Tillåten',
      },
      require: false,
      type: OptionType.TEXT,
      defaultValues: [product?.ipAllowed?.toString() || ''],
      possibleValues: [],
    },
    // originAllowed
    {
      ref: 'originAllowed',
      name: {
        en: 'Origin Allowed',
        es: 'Origen Permitido',
        da: 'Oprindelse Tilladt',
        fi: 'Alkuperä Sallittu',
        no: 'Opprinnelse Tillatt',
        sv: 'Ursprung Tillåten',
      },
      require: false,
      type: OptionType.TEXT,
      defaultValues: [product?.originAllowed?.toString() || ''],
      possibleValues: [],
    },
    // companyId
    !product
      ? {
          ref: 'companyId',
          name: {
            en: 'Company Id',
            es: 'Id de la empresa',
            da: 'Virksomheds-id',
            fi: 'Yrityksen tunnus',
            no: 'Selskaps-id',
            sv: 'Företags-id',
          },
          type: OptionType.SELECT,
          require: false,
          defaultValues: [selectedCompany],
          possibleValues: companies?.map((c) => c.id),
        }
      : {
          ref: 'status',
          name: {
            en: 'Status',
            es: 'Estado',
            da: 'Status',
            fi: 'Tila',
            no: 'Status',
            sv: 'Status',
          },
          type: OptionType.SELECT,
          require: true,
          defaultValues: [product?.status],
          possibleValues: ['ACTIVE', 'BLOCK', 'DELETE'],
        },
    // context
    {
      ref: 'context',
      name: {
        en: 'Context',
        es: 'Contexto',
        da: 'Kontekst',
        fi: 'Konteksti',
        no: 'Kontekst',
        sv: 'Sammanhang',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [product?.context || ''],
      possibleValues: [],
    },
    // challenges
    {
      ref: 'challenges',
      name: {
        en: 'Challenges',
        es: 'Desafíos',
        da: 'Udfordringer',
        fi: 'Haasteet',
        no: 'Utfordringer',
        sv: 'Utmaningar',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [product?.challenges || ''],
      possibleValues: [],
    },
    // issues
    {
      ref: 'issues',
      name: {
        en: 'Issues',
        es: 'Problemas',
        da: 'Problemer',
        fi: 'Ongelmat',
        no: 'Problemer',
        sv: 'Problem',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [product?.issues || ''],
      possibleValues: [],
    },
    // validations
    {
      ref: 'validations',
      name: {
        en: 'Validations',
        es: 'Validaciones',
        da: 'Valideringer',
        fi: 'Validoinnit',
        no: 'Valideringer',
        sv: 'Valideringar',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [product?.validations || ''],
      possibleValues: [],
    },
    // categories
    {
      ref: 'categories',
      name: {
        en: 'Categories',
        es: 'Categorías',
        da: 'Kategorier',
        fi: 'Kategoriat',
        no: 'Kategorier',
        sv: 'Kategorier',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [product?.categories?.toString() || ''],
      possibleValues: [],
    },
    // thresholdSim
    {
      ref: 'thresholdSim',
      name: {
        en: 'Threshold Sim',
        es: 'Umbral Sim',
        da: 'Tærskel Sim',
        fi: 'Kynnys Sim',
        no: 'Terskel Sim',
        sv: 'Tröskel Sim',
      },
      type: OptionType.NUMBER,
      require: true,
      defaultValues: [product?.thresholdSim?.toString() || '0'],
      possibleValues: [],
    },
    // lowerBound
    {
      ref: 'lowerBound',
      name: {
        en: 'Lower Bound',
        es: 'Límite inferior',
        da: 'Nedre grænse',
        fi: 'Alaraja',
        no: 'Nedre grense',
        sv: 'Nedre gräns',
      },
      type: OptionType.NUMBER,
      require: true,
      defaultValues: [product?.lowerBound?.toString() || '0'],
      possibleValues: [],
    },
    // upperBound
    {
      ref: 'upperBound',
      name: {
        en: 'Upper Bound',
        es: 'Límite superior',
        da: 'Øvre grænse',
        fi: 'Yläraja',
        no: 'Øvre grense',
        sv: 'Övre gräns',
      },
      type: OptionType.NUMBER,
      require: true,
      defaultValues: [product?.upperBound?.toString() || '0'],
      possibleValues: [],
    },
    // lang
    {
      ref: 'lang',
      name: {
        en: 'Lang',
        es: 'Idioma',
        da: 'Sprog',
        fi: 'Kieli',
        no: 'Språk',
        sv: 'Språk',
      },
      type: OptionType.LANGUAGE,
      require: false,
      defaultValues: [product?.lang || 'en'],
      possibleValues: langs || ['en', 'es'],
    },
    // longTailDeletion
    {
      ref: 'longTailDeletion',
      name: {
        en: 'Long tail deletion',
        es: 'Eliminación de cola larga',
        da: 'Lang hale sletning',
        fi: 'Pitkä häntä poisto',
        no: 'Lang hale sletting',
        sv: 'Lång svans borttagning',
      },
      type: OptionType.NUMBER,
      require: true,
      defaultValues: [product?.longTailDeletion?.toString() || '0'],
      possibleValues: [],
    },
    // thresholdUpdate
    {
      ref: 'thresholdUpdate',
      name: {
        en: 'Threshold update',
        es: 'Umbral de actualización',
        da: 'Tærskelopdatering',
        fi: 'Kynnyspäivitys',
        no: 'Terskeloppdatering',
        sv: 'Tröskeluppdatering',
      },
      type: OptionType.NUMBER,
      require: true,
      defaultValues: [product?.thresholdUpdate?.toString() || '0'],
      possibleValues: [],
    },
    // highImpactUpdate
    {
      ref: 'highImpactUpdate',
      name: {
        en: 'High impact update',
        es: 'Actualización de alto impacto',
        da: 'Høj indvirkning opdatering',
        fi: 'Korkea vaikutus päivitys',
        no: 'Høy innvirkning oppdatering',
        sv: 'Hög påverkan uppdatering',
      },
      type: OptionType.NUMBER,
      require: true,
      defaultValues: [product?.highImpactUpdate?.toString() || '0'],
      possibleValues: [],
    },
    // customIcons
    {
      ref: 'customIcons',
      name: {
        en: 'Custom Icons',
        es: 'Iconos personalizados',
        da: 'Brugerdefinerede ikoner',
        fi: 'Mukautetut kuvakkeet',
        no: 'Tilpassede ikoner',
        sv: 'Anpassade ikoner',
      },
      type: OptionType.BOOLEAN,
      require: false,
      defaultValues: [product?.customIcons ? 'true' : 'false'],
      possibleValues: [],
    },
  ],
  company: (company, verticals: string[]) => [
    // name
    {
      ref: 'name',
      name: {
        en: 'Name',
        es: 'Nombre',
        da: 'Navn',
        fi: 'Nimi',
        no: 'Navn',
        sv: 'Namn',
      },
      type: OptionType.TEXT,
      require: true,
      defaultValues: [company?.name || ''],
      possibleValues: [],
    },
    // website
    {
      ref: 'website',
      name: {
        en: 'Website',
        es: 'Sitio web',
        da: 'Hjemmeside',
        fi: 'Verkkosivusto',
        no: 'Nettsted',
        sv: 'Hemsida',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [company?.website || ''],
      possibleValues: [],
    },
    // allowedDomains
    {
      ref: 'allowedDomains',
      name: {
        en: 'Allowed Domains',
        es: 'Dominios permitidos',
        da: 'Tilladte domæner',
        fi: 'Sallitut verkkotunnukset',
        no: 'Tillatte domener',
        sv: 'Tillåtna domäner',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [company?.allowedDomains?.toString() || ''],
      possibleValues: [],
    },
    // allowedEmails
    {
      ref: 'allowedEmails',
      name: {
        en: 'Allowed Emails',
        es: 'Correos permitidos',
        da: 'Tilladte e-mails',
        fi: 'Sallitut sähköpostit',
        no: 'Tillatte e-poster',
        sv: 'Tillåtna e-postadresser',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [company?.allowedEmails?.toString() || ''],
      possibleValues: [],
    },
    // vertical
    {
      ref: 'vertical',
      name: {
        en: 'Vertical',
        es: 'Vertical',
        da: 'Vertikal',
        fi: 'Pystysuora',
        no: 'Vertikal',
        sv: 'Vertikal',
      },
      type: OptionType.SELECT,
      require: true,
      defaultValues: [company?.vertical || 'GENERAL'],
      possibleValues: verticals,
    },
    // client: Boolean (Default: false)
    {
      ref: 'client',
      name: {
        en: 'Client',
        es: 'Cliente',
        da: 'Klient',
        fi: 'Asiakas',
        no: 'Klient',
        sv: 'Klient',
      },
      type: OptionType.BOOLEAN,
      require: false,
      defaultValues: [company?.client ? 'true' : 'false'],
      possibleValues: [],
    },
    // logLevel: Enum por defecto NONE
    {
      ref: 'logLevel',
      name: {
        en: 'Log Level',
        es: 'Nivel de log',
        da: 'Logniveau',
        fi: 'Lokitaso',
        no: 'Loggnivå',
        sv: 'Loggnivå',
      },
      type: OptionType.SELECT,
      require: false,
      defaultValues: [company?.logLevel || 'NONE'],
      possibleValues: ['NONE', 'INTERNAL', 'PUBLIC'],
    },
    // logo
    {
      ref: 'logo',
      name: {
        en: 'Logo',
        es: 'Logotipo',
        da: 'Logo',
        fi: 'Logo',
        no: 'Logo',
        sv: 'Logotyp',
      },
      type: OptionType.LOGO,
      require: false,
      defaultValues: [company?.logo || ''],
      possibleValues: [],
    },
    // favIcon
    {
      ref: 'favIcon',
      name: {
        en: 'Favicon',
        es: 'Favicon',
        da: 'Favicon',
        fi: 'Favicon',
        no: 'Favicon',
        sv: 'Favicon',
      },
      type: OptionType.FAVICON,
      require: false,
      defaultValues: [company?.favIcon || ''],
      possibleValues: [],
    },
    // backgroundColor
    {
      ref: 'backgroundColor',
      name: {
        en: 'Background color',
        es: 'Color de fondo',
        da: 'Baggrundsfarve',
        fi: 'Taustaväri',
        no: 'Bakgrunnsfarge',
        sv: 'Bakgrundsfärg',
      },
      type: OptionType.COLOR,
      require: false,
      defaultValues: [company?.backgroundColor || ''],
      possibleValues: [],
    },
    // privacyPolicyUrl
    {
      ref: 'privacyPolicyUrl',
      name: {
        en: 'Privacy policy url',
        es: 'URL de la política de privacidad',
        da: 'Privatlivspolitik url',
        fi: 'Tietosuojakäytännön url',
        no: 'Personvernpolicy url',
        sv: 'Integritetspolicy url',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [company?.privacyPolicyUrl || ''],
      possibleValues: [],
    },
    // helpUrl
    {
      ref: 'helpUrl',
      name: {
        en: 'Help url',
        es: 'URL de ayuda',
        da: 'Hjælp url',
        fi: 'Ohje url',
        no: 'Hjelp url',
        sv: 'Hjälp url',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [company?.helpUrl || ''],
      possibleValues: [],
    },
  ],
  metric: (metric, selectedCompany, products) => [
    // name
    {
      ref: 'name',
      name: {
        en: 'Name',
        es: 'Nombre',
        da: 'Navn',
        fi: 'Nimi',
        no: 'Navn',
        sv: 'Namn',
      },
      type: OptionType.TEXT,
      require: true,
      defaultValues: [metric?.name || ''],
      possibleValues: [],
    },
    // metricName
    {
      ref: 'metricName',
      name: {
        en: 'Metric Name',
        es: 'Nombre de la métrica',
        da: 'Metrisk navn',
        fi: 'Metrinen nimi',
        no: 'Metrikknavn',
        sv: 'Metriknamn',
      },
      type: OptionType.TEXT,
      require: true,
      defaultValues: [metric?.metricName || ''],
      possibleValues: [],
    },
    // query
    {
      ref: 'query',
      name: {
        en: 'Query',
        es: 'Consulta',
        da: 'Forespørgsel',
        fi: 'Kysely',
        no: 'Spørring',
        sv: 'Förfrågan',
      },
      type: OptionType.LONGTEXT,
      require: true,
      defaultValues: [metric?.query || ''],
      possibleValues: [],
    },
    // goal
    {
      ref: 'goal',
      name: {
        en: 'Goal',
        es: 'Objetivo',
        da: 'Mål',
        fi: 'Tavoite',
        no: 'Mål',
        sv: 'Mål',
      },
      type: OptionType.SELECT,
      require: true,
      defaultValues: [metric?.goal || METRICGOAL.SEARCH],
      possibleValues: [
        METRICGOAL.BUG,
        METRICGOAL.CATEGORISE,
        METRICGOAL.EVALUATE,
        METRICGOAL.FEATURE_REQUEST,
        METRICGOAL.SEARCH,
        METRICGOAL.URL_PARSE,
      ],
    },
    // values
    {
      ref: 'values',
      name: {
        en: 'Values',
        es: 'Valores',
        da: 'Værdier',
        fi: 'Arvot',
        no: 'Verdier',
        sv: 'Värden',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [metric?.values?.toString() || ''],
      possibleValues: [],
    },
    // examples
    {
      ref: 'examples',
      name: {
        en: 'Examples',
        es: 'Ejemplos',
        da: 'Eksempler',
        fi: 'Esimerkit',
        no: 'Eksempler',
        sv: 'Exempel',
      },
      type: OptionType.TEXT,
      require: false,
      defaultValues: [metric?.examples?.toString() || ''],
      possibleValues: [],
    },
    // productId
    {
      ref: 'productId',
      name: {
        en: 'Product',
        es: 'Producto',
        da: 'Produkt',
        fi: 'Tuote',
        no: 'Produkt',
        sv: 'Produkt',
      },
      type: OptionType.SELECT,
      require: true,
      defaultValues: [metric?.productId || products[0]?.id || ''],
      possibleValues: products?.map((p) => p.id),
    },
  ],
};
