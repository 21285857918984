import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../data';

export function RenderLabel({ name }: { name?: string | any }) {
  const { t } = useTranslation();
  const { language } = useDataContext();

  try {
    if (!name || name === '' || name === null || name === undefined) {
      return '';
    }

    if (typeof name === 'string') {
      return t(`text.inputs.${name}`) === `text.inputs.${name}` ? name : t(`text.inputs.${name}`);
    }
    if (name[language]) {
      return name[language];
    }

    if (name.en) {
      return name.en;
    }

    return '';
  } catch (e) {
    return '';
  }
}
