/* eslint-disable react-hooks/exhaustive-deps */
import type {Transform} from '@dnd-kit/utilities';
import type {StackProps} from '@mui/material/Stack';
import Stack from '@mui/material/Stack';
import type {DraggableSyntheticListeners, UniqueIdentifier} from '@dnd-kit/core';

import {forwardRef, memo, useEffect} from 'react';

import Box from '@mui/material/Box';
import {styled, useTheme} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import Iconify from 'src/components/iconify';

import {itemClasses} from './classes';

// ----------------------------------------------------------------------

export const StyledItemWrap = styled(Box)(() => ({
  flexShrink: 0,
  display: 'flex',
  transform:
    'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))',
  transformOrigin: '0 0',
  touchAction: 'manipulation',
  [`&.${itemClasses.state.dragOverlay}`]: {
    zIndex: 99,
  },
}));

// ----------------------------------------------------------------------

type ItemBaseProps = StackProps & {
  item: UniqueIdentifier;
  onRemove?: () => void;
  stateProps?: {
    sorting?: boolean;
    dragging?: boolean;
    dragOverlay?: boolean;
    transition?: string | null;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    handleProps?: any;
  };
  renderItem?: (item: UniqueIdentifier) => React.ReactNode;
  isSelected?: (id: UniqueIdentifier) => boolean;
  type: string;
  isDragging?: boolean;
};

const ItemBase = forwardRef<HTMLLIElement, ItemBaseProps>(
  ({ item, stateProps, onRemove, renderItem, isSelected, sx, type, isDragging, ...other }, ref) => {
    const theme = useTheme();

    useEffect(() => {
      if (!stateProps?.dragOverlay) {
        return;
      }

      document.body.style.cursor = 'grabbing';

      // eslint-disable-next-line consistent-return
      return () => {
        document.body.style.cursor = '';
      };
    }, [stateProps?.dragOverlay]);

    const itemWrapClassName = itemClasses.itemWrap.concat(
      (stateProps?.dragOverlay && ` ${itemClasses.state.dragging}`) ||
        (stateProps?.dragOverlay && ` ${itemClasses.state.sorting}`) ||
        (stateProps?.dragOverlay && ` ${itemClasses.state.dragOverlay}`) ||
        ''
    );

    const background = () => {
      if (isSelected && isSelected(item)) {
        return theme.palette.primary.light + 25;
      }
      if (type === 'card') {
        return theme.palette.background.paper;
      }
      return 'transparent';
    };

    return (
      <StyledItemWrap
        ref={ref}
        component="div"
        id={`${type}-${item}`}
        className={itemWrapClassName}
        sx={{
          ...(!!stateProps?.transition && { transition: stateProps.transition }),
          ...(!!stateProps?.transform && {
            '--translate-x': `${Math.round(stateProps.transform.x)}px`,
            '--translate-y': `${Math.round(stateProps.transform.y)}px`,
            '--scale-x': `${stateProps.transform.scaleX}`,
            '--scale-y': `${stateProps.transform.scaleY}`,
          }),
          ...(isSelected && {
            backgroundColor: background(),
            [`&.${itemClasses.state.dragging}`]: {
              backgroundColor: background(),
            },
            ...(['item'].includes(type) && {
              border: `1px solid ${theme.palette.primary.light}`,
              borderRadius: 1,
            }),
            ...(['card'].includes(type) && {
              borderRadius: 1,
            }),

            ...(isSelected(item) && {
              boxShadow: theme.customShadows.z8,
              borderRadius: 1,
              borderColor: theme.palette.action.active,
            }),
          }),
          ...(['item'].includes(type) && {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
          }),
          width: '100%',

          ...(['card', 'item'].includes(type) && {
            my: 1,
            boxShadow: 1,
          }),
          ...(isDragging && {
            visibility: 'hidden', // Hide the static element when dragging
          }),
          ...(stateProps?.dragOverlay && {
            backgroundColor: background(),
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            m: 0,
          }),
        }}
      >
        <Stack
          direction="row"
          width="100%"
          sx={{
            ...(['card'].includes(type) && {
              borderRadius: 1,
              boxShadow: 1,
            }),
            ...(['item'].includes(type) && {
              borderRadius: 1,
              boxShadow: 1,
            }),
          }}
        >
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            {...stateProps?.handleProps}
            {...stateProps?.listeners}
            size="small"
            sx={{
              pr: 0,
              ml: 1,
            }}
          >
            <Iconify icon="nimbus:drag-dots" />
          </IconButton>
          {renderItem ? renderItem(item) : item}
        </Stack>
      </StyledItemWrap>
    );
  }
);

export default memo(ItemBase);
